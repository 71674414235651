@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  list-style: none;
}
:root{
  --main-bg : #E4E9F7;

  --light-white: #f0f0f0;
  --light-white-p: #6c757d;
  --color-main: #2E3F97;
  --color-main-light: #225b86;  
  --color-anchor: #0d6efd;
  --color-blue: #4d8aff;
  --color-dark-blue: #004269;
  --color-blu : #4387c0;
  --color-blue-light : #00AAA9;
  --color-orange : orange;
  --color-green : rgb(44, 185, 16);

  --border-color-1 : rgba(101, 119, 134, 0.36);
  --transition-3: all 300ms ease-in;
  --transition-4: all 400ms ease-in;
}
body{
  background: var(--light-white) !important;
  overflow-x: hidden;
}
body .rightside{
  margin-top: 4rem;
  margin-left: 250px;
  padding: 1rem;
}

a{
  color: inherit;
  text-decoration: none;
}
ol, ul {
  padding-left: 0rem;
  margin-bottom: 0rem;
}
@media only screen and (max-width: 768px){
  body .rightside{
    margin-left: 0px;
  }
}


/* ---- Breadcrumb ---  */
.bread{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--color-main-light);
  border-top: 2px solid var(--color-main-light);
  padding: 0.5rem 0rem;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.bread h4{
  margin-bottom: 0rem;
  color: var(--color-main);
}
.bread .links a ,
.bread .links button{
  font-size: 1.1rem;
  color: white;
  background: var(--color-main-light);
  padding: 0.4rem 1rem;
  border: none;
  outline: none;
}

/* --  */
.filteration {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--color-main-light);
  padding: 0.5rem 0rem;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.filteration option , 
.filteration select ,
.filteration input{
  outline: none;
  border: 1px solid var(--color-main-light) ;
}


/* -------- dis-table ----  */
.dis-table{
  width: 100%;
  margin: 1rem 0rem;
  overflow-x: auto;
  font-size: 0.88rem;
}
.dis-table i{
  font-size: 0.78rem;
}
.dis-table th,
.dis-table td{
  text-wrap: nowrap;
}
.dis-table th{
  font-weight: 500;
  min-width: max-content;
}
.dis-table img{
  height: 50px;
  width: 50px;
  object-fit: cover;
}
.dis-table td select{
  padding: 0.2rem;
  padding-right: 0.6rem;
  width: max-content;
  font-size: 0.9rem;
}
.dis-table .bt{
  padding: 2px 5px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  transition: var(--transition-3);
  border: none;
  outline: none;
  text-wrap: nowrap;
}
.dis-table .bt:hover{
  opacity: 0.5;
}
.dis-table .bt.edit{
  background: var(--color-green);
}
.dis-table .bt.delete{
  background: red;
}
.dis-table .bt.view{
  background: var(--color-main-light);
}



/* --- d-form ---  */
.d-form{
  margin: 1rem 0rem;
  padding: 1rem 0.5rem;
  background: white;
  border-radius: 10px;
}
.form-control , 
.form-select{
  border: 1px solid var(--color-blue-light) !important;
  outline: none;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: var(--color-blue-light) !important;
  outline: 0;
  box-shadow: none !important;
}
.form-check-input{
  border-color: var(--color-blue-light) !important;
}
.form-check-input:checked {
  background-color: var(--color-blue-light) !important;
  border-color: var(--color-blue-light) !important;
}
.form-check-input:focus ,
.form-select:focus{
  border-color: var(--color-blue-light) !important;
  box-shadow: none !important;
}
button[type="submit"]{
  padding: 0.3rem 1rem;
  border: 1px solid var(--color-main-light);
  outline: none;
  background: var(--color-main-light);
  background: var(--color-green);
  color: white;
  text-transform: uppercase;
  font-size: 1.1rem;
}



/* =======================  */

.property-card {
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.property-card:hover {
  transform: translateY(-5px);
}

.card-header.bg-gradient-primary {
  background: linear-gradient(45deg, var(--color-main), #1cc88a);
  border-bottom: none;
}

.card-body .description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
}

.card-body {
  padding: 15px;
  color: #6c757d;
}

.card-footer {
  background-color: #f8f9fa;
  border-top: none;
  padding: 10px;
}

.property-card .card-text {
  margin-bottom: 10px;
}

.property-card .form-select {
  border-radius: 20px;
  padding: 5px 10px;
}
